import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAttributesQuery, useGetHeroesQuery } from '../../store/api';
import styles from './Heroes.module.scss'
import { Header } from '../../components/Header';

export const Heroes = () => {
    const { data: heroes = [] } = useGetHeroesQuery()
    const { data: attributes = [] } = useGetAttributesQuery()
    const [pressedString, setPressedString] = useState('');
    const timerRef = useRef<number | null>(null);
    const navigate = useNavigate()

    const navigateThroughEnter = (name: string) => {
        const filteredHeroes = heroes.filter(hero =>
            hero.heroName.toLowerCase().includes(name.toLowerCase())
        );

        if (filteredHeroes.length === 1) {
            navigate(`/heroes/${filteredHeroes[0].heroName}`)
        }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape' || event.key === 'Backspace') {
            setPressedString('');
            return
        }

        if (event.key === 'Enter') {
            setPressedString((prev) => {
                navigateThroughEnter(prev)
                return prev
            })
            return
        }
        if (event.key.length > 1) {
            return
        }
        setPressedString((prev) => prev + event.key.toLowerCase());
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
            setPressedString('');
        }, 2000);
    }

    useEffect(() => {
        if (heroes.length) {
            window.addEventListener('keydown', handleKeyPress);

            return () => {
                window.removeEventListener('keydown', handleKeyPress);
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
            };
        }
    }, [heroes]);

    return (
        <div className={styles.container}>
            <h1>{pressedString}</h1>
            {attributes.map((attribute) => {
                return (
                    <div>
                        <h2><img src={attribute.img} width={24} height={24} /> {attribute.attributeName}</h2>
                        <div className={styles.attribute}>
                            {heroes.map((hero) => {
                                if (hero.attribute.attributeName === attribute.attributeName)
                                    return (
                                        <Link
                                            to={`/heroes/${hero.id}`}
                                            className={styles.hero}
                                        >
                                            <div className={styles.absoluteContainer}>
                                                <img src={hero.img} className={
                                                    hero.heroName.toLowerCase().includes(pressedString) || !pressedString ? '' : styles.opacity
                                                } />
                                                <h3 className={styles.heroLabel}>
                                                    {hero.heroName}
                                                </h3>
                                            </div>
                                        </Link>
                                    )
                            })}
                        </div>
                    </div>
                )
            })}
        </div >
    );
};