import { FC, useState } from "react";
import { TextField } from "../../components/TextField";
import styles from './Login.module.scss'
import { Button } from "../../components/Button";
import { LoginBody } from "../../store/api/types/Profile.types";
import { useNavigate } from "react-router-dom";
import { dotaApi, useLoginMutation } from "../../store/api";
import { useDispatch } from "react-redux";
import { SnackBar } from "../../components/Alert";

export const Login: FC = () => {
  const navigate = useNavigate();
  const [login] = useLoginMutation()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [label, setLabel] = useState('')

  const [profile, setProfile] = useState<LoginBody>({
    email: '',
    password: '',
  });

  const handleChange = (field: keyof LoginBody, value: string) => {
    setProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleClick = async () => {
    const response = await login(profile)
    if (!response.error) {
      localStorage.setItem('token', response.data.token)
      dispatch(dotaApi.util.invalidateTags(['Profile']))
      navigate('/')
    }
    else {
      //@ts-ignore
      if (typeof response.error.data === 'object') {
        //@ts-ignore
        const errorMessages = Object.entries(response.error.data)
          .map(([key, value]) => `${key}: ${value}`)
          .join('<br />');
        setLabel(errorMessages);
      } else {
        //@ts-ignore
        setLabel(response.error.data);
      }
      setIsOpen(true);
    }
  };
  return (
    <div className={styles.login}>
      <SnackBar label={label} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <TextField
        label="Email"
        onChange={(value) => handleChange('email', value)}
      />
      <TextField
        label="Password"
        type="password"
        onChange={(value) => handleChange('password', value)}
      />
      <Button label="Login" onClick={() => handleClick()} />
    </div>
  )
}