import { FC } from 'react'
import { Alert, Snackbar } from '@mui/material'
import styles from './SnackBar.module.scss'

export type SnackBarProps = {
  onClose?: () => void
  label?: string
  isOpen?: boolean
}

export const SnackBar: FC<SnackBarProps> = ({
  onClose,
  label = '',
  isOpen = false,
}) => {
  return (
    <Snackbar
      onClose={onClose}
      className={styles.snackBar}
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert variant="filled" severity="error" onClose={onClose} className={styles.alert}>
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      </Alert>
    </Snackbar>
  )
}
