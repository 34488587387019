import { ChangeEvent, FC } from 'react'
import styles from './TextField.module.scss'

export type TextFieldProps = {
  label?: string
  onChange?: (value: string) => void
  type?: 'text' | 'password'
}

export const TextField: FC<TextFieldProps> = ({ label, onChange, type = 'text' }) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <div className={styles.container}>
      <h4>{label}</h4>
      <input type={type} onChange={handleChange} className={styles.textField} />
    </div>
  )
}