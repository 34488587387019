import { FC, useState } from "react";
import { TextField } from "../../components/TextField";
import styles from './SignUp.module.scss';
import { Button } from "../../components/Button";
import { CreateProfile } from "../../store/api/types/Profile.types";
import { useCreateProfileMutation } from "../../store/api";
import { useNavigate } from "react-router-dom";
import { SnackBar } from "../../components/Alert";

export const SignUp: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [label, setLabel] = useState('')
  const [createProfile] = useCreateProfileMutation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<CreateProfile>({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    password2: ''
  });

  const handleChange = (field: keyof CreateProfile, value: string) => {
    setProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleClick = async () => {
    if (profile.password != profile.password2) {
      setLabel('Password and Confirm Password fields must be the same');
      setIsOpen(true)
      return
    }
    const response = await createProfile(profile)
    if (!response.error)
      navigate('/login')
    else {
      //@ts-ignore
      if (typeof response.error.data === 'object') {
        //@ts-ignore
        const errorMessages = Object.entries(response.error.data)
          .map(([key, value]) => `${key}: ${value}`)
          .join('<br />');
        setLabel(errorMessages);
      } else {
        //@ts-ignore
        setLabel(response.error.data);
      }
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.signUp}>
      <SnackBar label={label} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <TextField
        label="Username"
        onChange={(value) => handleChange('username', value)}
      />
      <TextField
        label="Email"
        onChange={(value) => handleChange('email', value)}
      />
      <TextField
        label="First Name"
        onChange={(value) => handleChange('first_name', value)}
      />
      <TextField
        label="Last Name"
        onChange={(value) => handleChange('last_name', value)}
      />
      <TextField
        label="Password"
        type="password"
        onChange={(value) => handleChange('password', value)}
      />
      <TextField
        label="Confirm password"
        type="password"
        onChange={(value) => handleChange('password2', value)}
      />
      <Button label="Sign Up" onClick={() => handleClick()} />
    </div>
  );
};